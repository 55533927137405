import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "../../images/products/detail1.jpg";
import detail2JPG from "../../images/products/detail2.jpg";
import detail3JPG from "../../images/products/detail3.jpg";
import Policy from "./Policy";
import ProductReviewItem from "components/ProductReviewItem";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import axios from 'axios';
import API_HOST from '../../config/config';
import ProductImageSlider from 'containers/ProductDetailPage/ProductImageSlider';
import { useCart } from '../../context/CartContext';
import { useAuth } from '../../contains/AuthContext';

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { name } = useParams<{ name: string}>();
  const { addToCart } = useCart();
  const { user } = useAuth();
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);  


  interface ProductReviewPerson {
      name:string;
      photo: number;
      image:string;
      user_info:string[]
  }
  interface ProductReview {
      name:string;
      rate: number;
      review: string;
      image:string;
      posted_dt:string;
      user_info:ProductReviewPerson;
  }

  interface product_detail {
    id:number;
    title:string;
    price:number;
    sort_description?:any;
    description:string;
    summary:string;
    faq:string;
    sizes:any;
    variants:any;
    variantType:any;
    status:any;
    image:string;
    slug:string;
    review_count:string;
    average_review:number;
    condition:string;
    images:string[];
    reviews:ProductReview[];
    favourite:boolean;
    attribute_detail?:any[];
  }
  interface product_basic_detail {
    id:number;
    title:string;
    price:number;
    sort_description?:any;
    description:string;
  }
  const [productBasic,setProductBasic] = useState<product_basic_detail|null>(null);
  const [product, setProduct] = useState<product_detail|null>(null);
  const [accordionDetail, setAccordionDetail] = React.useState([]);
  const attribute_detail = (product?.attribute_detail?.length ? [product?.attribute_detail[0]] : []);
  const [size, setSize] = React.useState(((attribute_detail.length > 0) ? attribute_detail[0]['all_value'] : []));
  const [attributeSelected, setAttributeSelected] = React.useState(((attribute_detail.length > 0) ? ((attribute_detail[0]['all_value'].length > 0) ? attribute_detail[0]['all_value'][0] : {}) : {}));
  
  useEffect(() => {
    const fetchBasicData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-product-basic-details',{
          slug : `${name}`,
          user_id:user?user.id:'0'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setProductBasic(response.data.data);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchBasicData();
  }, [name]);

  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-product-details',{
          slug : `${name}`,
          user_id:user?user.id:'0'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setProduct(response.data.data);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [productBasic]);
  useEffect(() => {
    if(product){
      if (product && product.images && product.images.length > 0) {
        console.log(product.images[0]);
      }
      const attribute_detail2 = (product?.attribute_detail?.length ? [product?.attribute_detail[0]] : []);
      setSize(((attribute_detail2.length > 0) ? attribute_detail2[0]['all_value'] : []));
      setAttributeSelected(((attribute_detail2.length > 0) ? ((attribute_detail2[0]['all_value'].length > 0) ? attribute_detail2[0]['all_value'][0] : {}) : {}));
      
      var newAccordionDetailArray: Array<any> = [];  
      if(product.description && product.description != "<p><br></p>")
      {
        newAccordionDetailArray.push({'name' : 'Description','content' : product.description});
      }
      if(product.faq && product.faq != "<p><br></p>")
      {
        newAccordionDetailArray.push({'name' : 'FAQ','content' : product.faq});
      }
      setAccordionDetail(newAccordionDetailArray as any);
    }
  }, [product]);
 
  

  // const notifyAddTocart = () => {
  //   toast.custom(
  //     (t) => (
  //       <NotifyAddTocart
  //         productImage={LIST_IMAGES_DEMO[0]}
  //         qualitySelected={qualitySelected}
  //         show={t.visible}
  //         sizeSelected={sizeSelected}
  //         variantActive={variantActive}
  //       />
  //     ),
  //     { position: "top-right", id: "nc-product-notify", duration: 3000 }
  //   );
  // };

  // const renderVariants = () => {
  //   if (!variants || !variants.length) {
  //     return null;
  //   }

  //   return (
  //     <div>
  //       <label htmlFor="">
  //         <span className="text-sm font-medium">
  //           Color:
  //           <span className="ml-1 font-semibold">
  //             {variants[variantActive].name}
  //           </span>
  //         </span>
  //       </label>
  //       <div className="flex mt-3">
  //         {variants.map((variant, index) => (
  //           <div
  //             key={index}
  //             onClick={() => setVariantActive(index)}
  //             className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${
  //               variantActive === index
  //                 ? "border-custom-golden dark:border-custom-golden"
  //                 : "border-transparent"
  //             }`}
  //           >
  //             <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
  //               <img
  //                 src={variant.thumbnail}
  //                 alt=""
  //                 className="absolute w-full h-full object-cover"
  //               />
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                  isActive
                    ? "bg-custom-golden border-custom-golden text-white"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        
        {/* ---------- 1 HEADING ----------  */}
        { productBasic && 
          <>
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
           {productBasic.title}
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            {/* <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={((attributeSelected?.attribute_price) ? (attributeSelected?.attribute_price) : (productBasic.price))}
            /> */}

            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-custom-golden" />
                <div className="ml-1.5 flex">
                  <span>{product?.average_review}</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                  {product?.review_count} reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{product?.condition.toLocaleUpperCase()}</span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div> */}
        {/*<div className="">{renderSizeList()}</div>*/}

        <div className="">
          {
            attribute_detail.length > 0 && (
              attribute_detail.map((item:any, index:any) => {
              return (<div key={index}>
                <div className="flex justify-between font-medium text-sm">
                  <label htmlFor="">
                    <span className="capitalize">
                      {item.name} :
                      <span className="ml-1 font-semibold uppercase">{attributeSelected.value}</span>
                    </span>
                  </label>                
                </div>
                <div className="grid grid-flow-col auto-cols-max gap-2 mt-2.5">
                  {
                    item.all_value.map((size:any, index2:any) => {
                    const isActive = ((size.product_attribute_value_id === attributeSelected.product_attribute_value_id) && (size.product_attribute_id === attributeSelected.product_attribute_id));
                    const sizeOutStock = false;
                    return (
                      <div
                        key={index2}
                        className={`relative px-2 h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                        text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                          sizeOutStock
                            ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                            : "cursor-pointer"
                        } ${
                          isActive
                            ? "bg-custom-golden border-custom-golden text-white hover:bg-custom-golden"
                            : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                        }`}
                        onClick={() => {
                          if (sizeOutStock) {
                            return;
                          }
                          setAttributeSelected(size);
                        }}
                      >
                        {size.value}
                      </div>
                    );
                  })}
                </div>
              </div>)
              })
            )
          }
        </div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        {/* <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              max={10}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => addToCart({...product,selected_attribute:attributeSelected},qualitySelected)} //{notifyAddTocart} //old function
            
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div> */}

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
          <AccordionInfo data={accordionDetail}/>
        
        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">
          <Policy />
        </div>
        
        </>}
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-custom-golden text-2xl font-semibold">Product Details</h2>
        { product && 
        <div className="w-full mt-7" dangerouslySetInnerHTML={{ __html: product.description }}>
        </div>
        }
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-custom-golden text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> {product?.average_review} · {product?.review_count} Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            {/* <ProductReviewItem /> */}
            { product && product.reviews && product.reviews.length > 0  &&
           product.reviews.map((item:ProductReview, index) => {
            return (
              <ProductReviewItem
              data={{
                comment:item.review,
                date: item.posted_dt,
                name: item.user_info.name,
                avatar: item.user_info.image,
                starPoint: item.rate,
              }}
            />)
            })}
          </div>

          {/* <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all {product?.review_count} reviews
          </ButtonSecondary> */}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      <Helmet>
        <title>{product?.title}</title>
        <meta property="og:site_name" content="Sufana" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={(product?.sort_description?.length > 255 ? `${product?.sort_description?.substring(0, 255)}` : product?.sort_description)} />
        <meta property="og:title" content={product?.title} />
        <meta property="og:image" itemProp="image" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:secure_url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* {/ CONTENT /} */}
          <div className="w-full lg:w-[500px] lg:basis-[500px] lg:grow lg:shrink-0 ">
            {/* {/ HEADING /} */}

            <div className="sticky top-[150px]">
            {
            product && product.images && product.images.length > 0 &&
              <ProductImageSlider images={product.images} />
            }
            {product && product.condition!=null && 
              <div className="absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300">
                <SparklesIcon className="w-3.5 h-3.5" />
              <span className="ml-1 leading-none">{product.condition.toLocaleUpperCase()}</span>
              </div>
            }
            {
            product &&
              <LikeButton liked={product.favourite} className="absolute right-3 top-3 " productId={product.id}/>
            }

              { !product && /* --- skeletant-design --- */
              <div className="w-full skeletant-target">
                  <div className="skeletant-design h-[340px] w-full"></div>
                  <div className="grid grid-cols-4 gap-2 my-2">
                    <div className="skeletant-design h-[100px]"></div>
                    <div className="skeletant-design h-[100px]"></div>
                    <div className="skeletant-design h-[100px]"></div>
                    <div className="skeletant-design h-[100px]"></div>
                  </div>
              </div>
              }
              {/* --- skeletant-design --- */}

            </div>



            {/* {/ Old Code Main img /} */}
            {/* <div className="hidden relative">
              <div className="aspect-w-16 aspect-h-16">
              {
              product && product.images && product.images.length > 0 &&
                <img
                  src={product.images[0]}
                  className="w-full rounded-2xl object-cover bg-[white]"
                  alt="product detail 1 "
                />
              }
              </div>
              {renderStatus()}
              
              <LikeButton className="absolute right-3 top-3 " />
            </div> */}

            {/* {/ Old Code Sub img /} */}
            {/* <div className="hidden grid-cols-4 gap-3 mt-3 sm:gap-3 sm:mt-3 xl:gap-3 xl:mt-3">
              {product && product.images && product.images.length > 0 &&
              product.images.map((item, index) => {
                return (
                  <div
                    key={index}
                    className=""
                  >
                    <img
                      src={item}
                      className="w-full rounded-2xl object-cover bg-[white]"
                      alt="product detail 1"
                    />
                  </div>
                );
              })}
              
            </div> */}
          </div>

          {/* {/ SIDEBAR /} */}
          <div className="w-full lg:w-[100% - 500px] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            { productBasic && renderSectionContent()}

            {/* --- skeletant-design --- */}
            { !productBasic &&
            <div className="w-full skeletant-target">
                <div className="skeletant-design ld-no-img h-[30px]"></div>
                <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                <div className="flex">
                  <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-4 mr-2"></div>
                  <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-4"></div>
                </div>
                <div className="flex">
                  <div className="skeletant-design ld-no-img h-[50px] w-[200px] mt-4 mr-2"></div>
                  <div className="skeletant-design ld-no-img h-[50px] w-full mt-4"></div>
                </div>
                <div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                </div>
                <div className="hidden xl:grid grid-cols-2 gap-2 my-2 mt-4">
                    <div className="skeletant-design ld-no-img h-[100px]"></div>
                    <div className="skeletant-design ld-no-img h-[100px]"></div>
                    <div className="skeletant-design ld-no-img h-[100px]"></div>
                    <div className="skeletant-design ld-no-img h-[100px]"></div>
                </div>
            </div>
            }   
            {/* --- skeletant-design --- */}
          </div>
        </div>
        

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          <div className="block xl:hidden">
            <Policy />
          </div>

          {product && renderDetailSection()}

          {/* --- skeletant-design --- */}
          { !product &&
          <div className="skeletant-target">
            <div className="skeletant-design ld-no-img h-[30px] max-w-[200px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
          </div>
          }
          {/* --- skeletant-design --- */}

          <hr className="border-slate-200 dark:border-slate-700" />

          {product && renderReviews()}

          {/* --- skeletant-design --- */}
          { !product &&
          <div className="skeletant-target ">
            <div className="skeletant-design ld-no-img h-[30px] max-w-[200px] mt-4 mb-4"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">

              <div className="p-5 bg-[#19243623] rounded-lg">
                <div className="flex items-center">
                  <div className="skeletant-design w-[60px] h-[60px] rounded-full"></div>
                  <div className="w-full pl-3">
                    <div className="skeletant-design ld-no-img h-[18px] w-[100px]"></div>
                    <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                  </div>
                </div>
                <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              </div>

              <div className="p-5 bg-[#19243623] rounded-lg">
                <div className="flex items-center">
                  <div className="skeletant-design w-[60px] h-[60px] rounded-full"></div>
                  <div className="w-full pl-3">
                    <div className="skeletant-design ld-no-img h-[18px] w-[100px]"></div>
                    <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                  </div>
                </div>
                <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
                <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              </div>

            </div>
          </div>
          }
          {/* --- skeletant-design --- */}


          <hr className="border-slate-200 dark:border-slate-700" />

          {/* OTHER SECTION */}
          {product && <SectionSliderProductCard
            heading="Customers also purchased"
            subHeading=""
            headingFontClassName="text-custom-golden text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          />
          }

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            <SectionPromo2 />
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage;

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet-async";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCard2 from "components/SectionSliderProductCard2";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
// import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
// import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";

function PageHome() {

  const [page, setPage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const sendRequestRef = useRef(true);

  useEffect(() => {        
    setLoading(true);        
    sendRequestRef.current = true;
    setPage(1);
  },[]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(sendRequestRef.current === true) {
          sendRequestRef.current = false;
          setLoading(true);            
          setPage((prevPage:any) => prevPage + 1);
          sendRequestRef.current = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 375 && !loading) {
        if(page != '')
        {
          fetchData();
        }
      }
    };
      
    if((page == 1 || page == 2))
    {
      if(page != '')
      {
        fetchData();
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, page]);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Sufana</title>
        <meta property="og:site_name" content="Sufana" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='At Sufana, we spice up your life – literally. Engage with our spice trading division, where we trade in aromatic spices and fast-moving consumer goods (FMCG), ensuring a flavorful journey for our clients. Sufana General Trading is where innovation meets excellence across a diverse spectrum of services. Our commitment extends to the artisanal realm with "Sufana," our exclusive brand offering handmade bath and body products. As a multifaceted company, we also specialize in delivering cutting-edge IT services, strategic investment solutions, and a seamless e-commerce experience. Additionally, we pioneer in virtual reality solutions, shaping the future of immersive experiences.' />
        <meta property="og:title" content="Sufana" />
        <meta property="og:image" itemProp="image" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:secure_url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>
      {/* SECTION HERO */}
      { page > 1 && <SectionHero2 /> }

      {/* --- skeletant-design --- */}
      <div className="skeletant-target hidden">
        <div className="skeletant-design ld-no-radius h-[380px] w-full mb-8"></div>
      </div>
      {/* --- skeletant-design --- */}
      { page > 2 && 
        <div className="mt-16 lg:mt-16">
          <DiscoverMoreSlider />
        </div>
      }

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        { page > 3 && 
          <SectionSliderProductCard
            data={[
              PRODUCTS[4],
              SPORT_PRODUCTS[5],
              PRODUCTS[7],
              SPORT_PRODUCTS[1],
              PRODUCTS[6],
            ]}
          />
        }

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700"> */}
        {/* { page > 4 && <SectionHowItWork /> } */}
        { page > 4  }
        {/* </div> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div> */}

        {/* SECTION */}
        { page > 5 && <SectionGridFeatureItems /> }

        {/*  */}
        { page > 6 && <SectionPromo2 /> }

        {/* SECTION 3 */}
        { page > 7 && <SectionSliderLargeProduct cardStyle="style2" /> }

        {/*  */}
        { page > 8 && <SectionSliderCategories /> }

        {/* SECTION */}
        {/* <SectionPromo3 /> */}

        { page > 9 && 
          <SectionSliderProductCard2
            heading="Best Sellers"
            subHeading="Best selling of the month"
          />
        }

        { page > 10 && 
          <div className="relative py-24 lg:py-32">
            <BackgroundSection />
            <div>
              <Heading rightDescText="From the Sufana blog">
                The latest news
              </Heading>
              <SectionMagazine5 />
              <div className="flex mt-16 justify-center">
                {/* <ButtonSecondary>Show all blog articles</ButtonSecondary> */}
              </div>
            </div>
          </div>
        }

        {/*  */}
        { page > 11 && <SectionClientSay /> }
      </div>
    </div>
  );
}

export default PageHome;

import React , { FC } from "react";
import { Link } from "react-router-dom";

import empty from "../../images/icons/empty.png"

export interface EmptyBoxProps {
  show?: string;
}

const EmptyBox: FC<EmptyBoxProps> = ({
  show = '',
}) => {
  return (
    <div className={`flex flex-col justify-center items-center py-16 ${show}`}>
        <img className="w-[100px] h-[100px] mb-3 invert" src={empty} alt="Data is not available" />
        <div className="text-[20px] text-[#f2f1f3] text-center font-medium mb-3">No results</div>
        <div className="text-[16px] text-[#bfbac4] text-xs leading-normal mb-3">Try adjusting your filters to get more results.</div>
        <Link to="/" className="transition-all px-4 py-2.5 rounded-full text-sm bg-[#187e8145] hover:bg-[#187e81]">Back to Home</Link>
    </div>
  );
};

export default EmptyBox;
import Label from "components/Label/Label";
import React, { FC, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from '../../contains/axios';
import API_HOST from '../../config/config';
import { useAuth  } from '../../contains/AuthContext';
import * as $ from 'jquery';
import 'jquery-validation';
import moment from 'moment';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { avatarImgs } from "contains/fakeData";

import * as Img from '../../images/icons/user.png';

export interface AccountPageProps {
  className?: string;
}

interface JQuery {
  $: any; // Replace with your types
}

interface country {
  id:number;
  name:string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [countries, setCountries] = useState([]);
  const [userDetail, setUserDetail] = useState<any>({});

  const imageRef = useRef<HTMLInputElement>(null);

  const [profilePicName, setProfilePicName] = useState('');
  const [profilePic, setProfilePic] = useState(avatarImgs[10]);
  const [selectedProfilePic, setSelectedProfilePic] = useState<File | null>(null);
  useEffect(() => {},[selectedProfilePic]);

  const [phoneValue, setPhoneValue] = useState<any>('');
  const [phoneMsg, setPhoneMsg] = useState<any>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleInputChange = (val: any) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
      setPhoneMsg('');
    }    
  };

  var phoneNumberCheckCount = 0;
  const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
      setPhoneMsg('');
    }
    if(phoneNumberCheckCount == 0)
    {
      setPhoneMsg('');
    }
    phoneNumberCheckCount = phoneNumberCheckCount+1;
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
  }

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (user) {
      if (user?.token && user?.role && user?.role=='user') {
      }
      else
      {
        navigate('/login');
      }
    }
    else
    {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchData();
    fetchUserDetailAPI();
  }, []);

  const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-countries',{
          nopaginate : '1',
          status : '1',
          user_id:user?user.id:'0'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setCountries(response.data.data);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


  async function fetchUserDetailAPI() {
    await axios.post(API_HOST+'/api/get-profile',{
      id : `${user?.id}`,
    })
    .then(response => {
      if(response.data.success == 0)
      {
        setUserDetail(response.data.data);
      }
    })
    .catch(error => {
      setUserDetail({});
      console.error('Error fetching data:', error);
    });        
  }

  useEffect(() => {
    if(typeof userDetail !== 'undefined' && userDetail !== null && Object.keys(userDetail).length > 0) 
    {
      handleInputChange(userDetail.phone_number || '');
      setProfilePic(userDetail.image);
      setProfilePicName(userDetail.photo || '');
      setPhone(userDetail.phone || '');   
      if(moment(userDetail.birthdate, 'YYYY-MM-DD', true).isValid())
      {
          setBirthdate(moment(userDetail.birthdate).format("YYYY-MM-DD"));
      }   
    }
  },[userDetail]);
  useEffect(() => {},[birthdate]);
  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10000);
    }
  }, [successMsg]);
  useEffect(() => {
    if (formRef.current) {
      setErrMsg([]);
      setSuccessMsg([]);      
      ($ as any)(formRef.current).validate({
        rules: {
          name: {
            required: true,
          },          
          email: {
            required: true,
            email: true,
          },  
          address: {
            required: true,
          }, 
          city: {
            required: true,
          },
          state: {
            required: true,
          },
          country: {
            required: true,
          },
          postal_code: {
            required: true,
          },       
        },
        messages: {
          name: {
            required: 'Name is required',
          },        
          email: {
            required: 'Email is required',
            email: 'Invalid email address',
          },
          address: {
            required: 'Address is required',
          },
          city: {
            required: 'City is required',
          },
          state: {
            required: 'State is required',
          },
          country: {
            required: 'Country is required',
          },
          postal_code: {
            required: 'Postal code is required',
          },          
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block p-1 text-[14px] text-[#fa826a]');   
          if (element.attr("name") == "phone" ) {
          }
          else
          {
            error.insertAfter(element.parent());
          }       
        },        
      });
    }
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg([]);
    setSuccessMsg([]);
    phoneNumberCheck();
    if (formRef.current) {      
      if(formRef.current.checkValidity())
      {
        if(phoneNumberCheck() == true)
        {
          setErrMsg([]);
          setSuccessMsg([]);
          const formData = new FormData(formRef.current);          
          formData.append('id',`${user?.id}`);
          if((isValidPhoneNumber((phoneValue || ''))) == true)
          {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
              formData.append('phone', `${phoneNumber.number}`);
              formData.append('phone_country', `${phoneNumber.country}`);
            }
          }
          if(selectedProfilePic)
          {
            formData.append('photo',selectedProfilePic);
          }
          
          try {
            axios.post(API_HOST+'/api/edit_profile',formData)
            .then(response => {
              if(response.data.success === 0)
              {
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                login({...response.data.data,token : user?.token});
                setSuccessMsg(successArray);
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];              
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "User Detail Update Failed";
              }
              else{
                newArray['server_error' as any] = "User Detail Update Failed";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            var newArray: Array<any> = [];
            newArray['server_error' as any] = "User Detail Update Failed";
            setErrMsg(newArray);
            console.error('Error fetching data:', error);
          }
        }
      }
      else
      {
        phoneNumberCheck();
      }
    }
  }

  function checkPhone(value:any)
  {
    if(value != ""){
      const phone_result = (/^[0-9]{10}$/).test(value);         
      if(value.length <= 10 && !(/^[0-9]{11}$/).test(value))
      {
        setPhone(value);      
      }
      else
      {
        setPhone(phone);
      }
    }else{ 
      setPhone(value);
    }    
  }

  useEffect(() => {},[errMsg]);

  const deleteProfilePic = () => {
    setErrMsg([]);
    setSuccessMsg([]);
    try {
      const response = axios.post('/api/remove_profile_pic',{
        id : `${user?.id}`,
      },{headers: {
          "Content-Type": "multipart/form-data",
          withCredentials: true
        }},
      )
      .then(response => {
        if(response.data.success == 0 || response.data.success == '0' )
        {
          var successArray: Array<any> = [];
          successArray["success" as any] = response.data.message;
          setSuccessMsg(successArray);
          setProfilePic(avatarImgs[10])
          setProfilePicName('');
          setSelectedProfilePic(null);                
        }                
      })
      .catch(error => {
        var newArray: Array<any> = [];
        if(!error?.response)
        {
          newArray["server_error" as any] = ('No Server Response.');
        }
        else if(error.response.data.success == 2)
        {
          newArray["server_error" as any] = (error.response.data.message);
        }
        else if(error.response?.status === 409 )
        {
          newArray["server_error" as any] = ('Profile picture not delete.');
        }
        else{
          newArray["server_error" as any] = ('Profile picture not delete.');
        } 
        setErrMsg(newArray);      
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setSelectedProfilePic(selectedFile ?? null);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Sufana</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Account infomation
          </h2>

          {/* FORM */}
          {
            (Object.keys(errMsg).length > 0) && (
              <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Error
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  {
                    Object.keys(errMsg).map((item:any,index:any) => (
                      <p key={index}>{errMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (Object.keys(successMsg).length > 0) && (
              <div role="alert">
                <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                  Success
                </div>
                <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                  {
                    Object.keys(successMsg).map((item:any,index:any) => (
                      <p key={index}>{successMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          <form ref={formRef} onSubmit={handleFormSubmit} encType="multipart/form-data" >
            <div className="flex flex-col md:flex-row">
              <div className="flex-shrink-0 flex items-start">
                {/* AVATAR */}
                <div className="relative rounded-full overflow-hidden flex">
                  <img
                    src={selectedProfilePic ? URL.createObjectURL(selectedProfilePic) : profilePic}
                    alt=""
                    className="w-32 h-32 rounded-full object-cover z-0"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="mt-1 text-xs">Change Image</span>
                  </div>
                  {/*{profilePicName && <div title="Remove Pic" className="profile-pic-btn delete-btn" onClick={() => deleteProfilePic()} ><i className="fa fa-trash-o"></i></div>}*/}
                  <input type="file" ref={imageRef} name="photo" id="profile-photo" className="absolute inset-0 opacity-0 cursor-pointer" accept="image/*" onChange={handleFileChange} />
                </div>
              </div>
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                <div>
                  <Label>Full name</Label>
                  <div className="mt-1.5 flex">
                    <Input id="name" name="name" type="text" placeholder="Enter name" className="" defaultValue={userDetail?.name} />
                  </div>
                </div>
                
                <div>
                  <Label>Email</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-envelope"></i>
                    </span>
                    <Input id="email" name="email" type="email" placeholder="Enter email address" className="!rounded-l-none" defaultValue={userDetail?.email} />
                  </div>
                </div>

                <div>
                  <Label>Date of birth</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-calendar"></i>
                    </span>
                    <Input id="birthdate" name="birthdate" className="!rounded-l-none" type="date" defaultValue={birthdate} />
                  </div>
                </div>
                {/* ---- */}
                {/*<div>
                  <Label>Addess</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-map-signs"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      defaultValue="New york, USA"
                    />
                  </div>
                </div>*/}

                {/* ---- */}
                <div>
                  <Label>Gender</Label>
                  <div className="mt-1.5 flex">
                  <Select id="gender" name="gender" className="" value={userDetail?.gender} required>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Select>
                  </div>
                </div>

                {/* ---- */}
                {/*<div>
                  <Label>Phone number</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-phone-volume"></i>
                    </span>
                    <Input id="phone" name="phone" type="text" onChange={(e) => checkPhone(e.target.value)} defaultValue={phone} placeholder="Enter number" className="!rounded-l-none" />
                  </div>
                </div>*/}
                <div>
                  <Label>Phone number</Label>
                  <div className="mt-1.5 flex">
                    <PhoneInput
                      inputclass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 h-12`}
                      international={false}
                      defaultCountry="AE"
                      id="phone"
                      name="phone"
                      value={phoneValue}
                      onChange={handleInputChange}
                    />
                  </div>
                  {(phoneMsg) && <label id="" className={` block p-1 text-[14px] text-[#fa826a]`} >{phoneMsg}</label>}
                </div>
                {/* ---- */}
                <div>
                  <Label>Addess</Label>
                  <div className="mt-1.5 flex">
                    <Textarea id="address" name="address" className="" defaultValue={userDetail?.address} />
                  </div>
                </div>
                {/* ============ */}
                <div>
                  <Label className="text-sm">City</Label>
                  <div className="mt-1.5 flex">
                    <Input className="mt-1.5" type="text" id="city" name="city" defaultValue={userDetail?.city} />
                  </div>
                </div>
                <div>
                  <Label className="text-sm">State/Province</Label>
                  <div className="mt-1.5 flex">
                    <Input className="mt-1.5" type="text" id="state" name="state" defaultValue={userDetail?.state} />
                  </div>
                </div>                  

                {/* ============ */}
                
                <div>
                  <Label className="text-sm">Country</Label>
                  <div className="mt-1.5 flex">
                    <Select id="country" name="country" className="mt-1.5" value={userDetail?userDetail.country:''}  >
                    <option value="">Select Country</option>
                    {countries && countries.map((item:country, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                    </Select>
                  </div>
                </div>
                <div>
                  <Label className="text-sm">Postal code</Label>
                  <div className="mt-1.5 flex">
                    <Input className="mt-1.5" type="text" id="postal_code" name="postal_code" defaultValue={userDetail?.postal_code} />
                  </div>
                </div>
       
                <div className="pt-2">
                  <ButtonPrimary type="submit" >Update account</ButtonPrimary>
                </div>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;

import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { AuthProvider } from '../contains/AuthProvider';
import RequireAuth from '../RequireAuth';

import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import FlashMessage from "shared/FlashMessage/FlashMessage";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";

import PageThankyou from 'containers/PageThankyou/PageThankyou'

// Static Pages file
import PageAbout from "containers/PageAbout/PageAbout";
import PageFaqs from "containers/PageLagle/PageFaqs";
import PageReturn from "containers/PageLagle/PageReturn";
import PagePrivacy from "containers/PageLagle/PagePrivacy";
import PageTerms from "containers/PageLagle/PageTerms";
import PageSitemap from "containers/PageLagle/PageSitemap";
import PageContact from "containers/PageContact/PageContact";

// Login pages file
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageForgot from "containers/PageForgot/PageForgot";
import PageReset from "containers/PageForgot/PageReset";
import PageSubcription from "containers/PageSubcription/PageSubcription";

// BLogs pages file
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";

import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
// import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
// import PageCollection2 from "containers/PageCollection2";

import CategoryCollection from "containers/CategoryCollection";

import { Toaster } from "react-hot-toast";


export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },

  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage },

  //
  { path: "/page-collection-2", component: PageCollection },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },

  //
  { path: "/account", component: AccountPage, allowedRoles : 'user' },
  { path: "/account-savelists", component: AccountSavelists, allowedRoles : 'user' },
  { path: "/account-change-password", component: AccountPass, allowedRoles : 'user' },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder, allowedRoles : 'user' },

  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/thankyou", component: PageThankyou },

  // BLog list & detail pages
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },


  // Static & cintact pages
  { path: "/about", component: PageAbout },
  { path: "/faqs", component: PageFaqs },
  { path: "/return", component: PageReturn },
  { path: "/privacy", component: PagePrivacy },
  { path: "/terms", component: PageTerms },
  { path: "/sitemap", component: PageSitemap },
  { path: "/contact", component: PageContact },

  // Login pages
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: PageForgot },
  { path: "/reset-password/:token/:email", component: PageReset },
  { path: "/subscription", component: PageSubcription },

  //
  { path: "/category", component: CategoryCollection },
  { path: "/category/:name", component: CategoryCollection },
  { path: "/product/:name", component: ProductDetailPage },
  { path: "/blog/:name", component: BlogSingle },
  { path: "/search", component: CategoryCollection },
  { path: "/search/:search", component: CategoryCollection },

  { path: "/unauthorized", component: Page404 },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path, allowedRoles }, index) => {
            if(allowedRoles && allowedRoles == 'user')
            {
              return (<Route key={index} element={<RequireAuth allowedRoles={["user"]} />} >
                        <Route element={<Component />} path={path} />
              </Route>);
            }
            else
            {
              return <Route key={index} element={<Component />} path={path} />;
            }
        })}
        <Route element={<Page404 />} />
      </Routes>
      <FlashMessage />
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;

import React, { useEffect, useState } from "react";
import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";
//import { NAVIGATION_DEMO_2 } from "data/navigation";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import API_HOST from '../../config/config';

function Navigation() {
  
  const [headerCategory, setHeaderCategory] = useState([]);
  const [headerPrimaryCategory, setHeaderPrimaryCategory] = useState([]);
  const [headerMoreCategory, setHeaderMoreCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-category-list',{
          nopaginate : '1',
          status : '1',
          order : 'asc',
          _token: 'asdsasa',
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setHeaderCategory(response.data.data.categories);
            setHeaderPrimaryCategory(response.data.data.categories.slice(0, 6));
            setHeaderMoreCategory(response.data.data.categories.slice(6, response.data.data.length));
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  return (
    <ul className="nc-Navigation flex items-center">
      {/* {NAVIGATION_DEMO_2.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))} */}
      {/* {headerCategory.length > 0 && (headerCategory.map((category:NavItemType) => (
        <NavigationItem key={category.id} menuItem={category} />
      )
      ))}
       */}
      {headerPrimaryCategory.length > 0 && (headerPrimaryCategory.map((category:NavItemType) => (
        <NavigationItem key={category.id} menuItem={category} />
      )
      ))}
      {headerPrimaryCategory.length > 0 && headerMoreCategory.length > 0 && 
      <li className="menu-item flex-shrink-0">
        <div className="custome-drop-bx h-20 flex-shrink-0 flex items-center">
          <div className="header-menu-item inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200">
            More 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>

          <div className="custome-drop-item">
          {headerMoreCategory.length > 0 && (headerMoreCategory.map((category:NavItemType,index:any) => (
             <NavLink key={index}
             target={category.targetBlank ? "_blank" : undefined}
             rel="noopener noreferrer"
             to={{
               pathname: category.href || undefined,
             }}
           >
             {category.name}
           </NavLink>
              // <a className="" href={category.href}>{category.name}</a>
              // <a>Solid Perfume</a>
              // <a>Shampoo Bar</a>
              // <a>Facial Cleanser</a>
          )))}
          </div>

        </div>
      </li>
      }

      {/* <li className="menu-item flex-shrink-0">
        <div className="custome-drop-bx h-20 flex-shrink-0 flex items-center">
          <div className="header-menu-item inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200">
            More 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>

          <div className="custome-drop-item">
              <a className="active">Whipped cream soap</a>
              <a>Solid Perfume</a>
              <a>Shampoo Bar</a>
              <a>Facial Cleanser</a>
          </div>

        </div>
      </li> */}
    </ul>
  );
}

export default Navigation;

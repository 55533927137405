import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionHero from "./SectionHero";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionPromo3 from "components/SectionPromo3";
// import rightImg from "images/hero-right1.png";
import PageOurTeam from "./PageOurTeam";

import visionmission from "images/visionmission.png";
import ab2 from "images/ab2.jpg";
import ab from "images/ab.jpg";

export interface PageAboutProps {
  className?: string;
}

const CheckSVG = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284"/></svg>
  )
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <>    
    {/* <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout"> */}
    {/* </div> */}
    {/* <BgGlassmorphism /> */}

    <Helmet>
      <title>About || Sufana</title>
    </Helmet>

    <section className="pt-16 pb-16 relative z-10">
        <div className="lagle-container">
            <div className="lagle-heading">About us</div>

            <div className="legal-content">
                <p>At Sufana, we spice up your life – literally. Engage with our spice trading division, where we trade in aromatic spices and fast-moving consumer goods (FMCG), ensuring a flavorful journey for our clients. Sufana General Trading is  where innovation meets excellence across a diverse spectrum of services. Our commitment extends to the artisanal realm with "The Body Bucket," our exclusive brand offering handmade bath and body products. As a multifaceted company, we also specialize in delivering cutting-edge IT services, strategic investment solutions, and a seamless e-commerce experience. Additionally, we pioneer in virtual reality solutions, shaping the future of immersive experiences.</p>
                <p>Embark on a journey with Sufana General Trading, where versatility, quality, and innovation converge to redefine industry standards.</p>
            </div>

            <div className="py-0 md:py-4"></div>

            <div className="my-6 md:my-14 pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 items-center relative">
              <div className="flex-grow mb-8 md:mb-0">
                <img className="w-full" src={visionmission} alt="" />
              </div>

              <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                <div className="lagle-heading lh-left">Our Process</div>
                <div className="legal-content">
                  <div className="main-sub-lable">Vision</div>
                  <p>To be a globally recognized leader, inspiring positive change and innovation across diverse industries. Sufana General Trading envisions a future where our comprehensive range of services transforms lives, businesses, and experiences, leaving an indelible mark on the world.</p>

                  <div className="pt-3"></div>

                  <div className="main-sub-lable">Mission</div>
                  <p>Sufana General Trading is dedicated to delivering unparalleled excellence across Spice Trading, Organic Foods Handmade Bath and Body products, IT services, Investment Solutions, E-commerce, Handmade Bath and Body products and VR solutions. Our mission is to empower individuals and businesses through strategic, innovative, and sustainable practices. We are committed to fostering growth, embracing diversity, and exceeding expectations to create lasting value for our clients and communities.</p>
                </div>
              </div>

            </div>

            <div className="py-0 md:py-4"></div>

            <div className="my-6 md:my-14">
              <div className="pt-10 md:pt-5 flex flex-col-reverse md:flex-row md:space-x-10 relative">

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content">
                    <div className="lagle-heading lh-left">Company Values</div>
                    <div className="main-sub-lable">Integrity</div>
                    <p>We conduct our business with the highest ethical standards, maintaining transparency, honesty and reliability in all our interactions with clients, partners and stakeholders.</p>

                    <div className="main-sub-lable">Customer-Centricity</div> 
                    <p>Our clients are at the heart of everything we do. We are committed to understanding their needs, exceeding expectations and building long-term relationships based on trust and satisfaction.</p>
                    
                    <div className="main-sub-lable">Quality Excellence</div> 
                    <p>We uphold uncompromising standards of quality in our products and services, ensuring that each offering reflects the precision, craftsmanship, and excellence synonymous with the Sufana brand.</p>

                    <div className="main-sub-lable">Collaboration</div> 
                    <p>We believe in the power of collaboration and teamwork. By fostering open communication and cooperation, we leverage the collective strengths of our diverse team to achieve shared goals.</p>

                    <div className="main-sub-lable">Adaptability</div> 
                    <p>In a dynamic and ever-evolving business landscape, we embrace change with agility and resilience. We proactively adapt to new challenges and opportunities, ensuring our relevance and success in the long run.</p>

                    <div className="main-sub-lable">Innovation</div> 
                    <p>We embrace creativity and foster a culture of continuous improvement, driving innovation across all aspects of our business to stay at the forefront of industry advancements.</p>
                  </div>
                </div>

                <div className="flex-grow mb-8 md:mb-0">
                    <img className="w-full sticky top-[155px]" src={ab2} alt="" />
                </div>

              </div>
            </div>

            <div className="py-0 md:py-4"></div>

            <div className="my-6 md:my-14">
              <div className="pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 relative">

                <div className="flex-grow mb-8 md:mb-0">
                    <img className="w-full sticky top-[155px]" src={ab} alt="" />
                </div>

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content lg-svg-dot">

                    <div className="lagle-heading lh-left">WHAT WE DO</div>
                    <div className="main-sub-lable">{CheckSVG()}Spice Trading and FMCG</div>
                    <p>Dive into a world of flavors with our spice trading division, where we source and trade premium spices and fastmoving consumer goods. Our commitment to quality ensures that our products not only meet but exceed the expectations of culinary enthusiasts and businesses alike.</p>

                    <div className="main-sub-lable">{CheckSVG()}The Body Bucket</div>
                    <p>'The Body Bucket,' our exclusive brand, brings the artistry of handmade bath and body products to discerning consumers. Each product is meticulously crafted using premium ingredients, combining traditional techniques with contemporary sensibilities to elevate the self-care experience.</p>

                    <div className="main-sub-lable">{CheckSVG()}Integrated IT Solutions</div>
                    <p>From cutting-edge Information Technology services that streamline operations to immersive Virtual Reality solutions that redefine experiences, we provide a comprehensive suite of tech-driven solutions tailored to meet the evolving needs of businesses.</p>

                    <div className="main-sub-lable">{CheckSVG()}E-Commerce Innovation</div>
                    <p>In the realm of e-commerce, we create immersive online experiences that transcend transactional boundaries. Our platform seamlessly connects buyers and sellers, providing a user-centric interface, secure transactions, and innovative features that enhance the overall online shopping experience.</p>


                  </div>
                </div>

              </div>
            </div>

            <div className="py-0 md:py-4"></div>

            <div className="my-6 md:my-14 pt-10 md:pt-5">
              <div className="hidden md:block lagle-heading">Meet The Team</div>
              <div className="block md:hidden lagle-heading lh-left">Meet The Team</div>
              <PageOurTeam />
            </div>

        </div>
    </section>

    </>

  );
};

export default PageAbout;

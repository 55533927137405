import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { NavLink } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Getting started",
  //   menus: [
  //     { href: "#", label: "Release Notes" },
  //     { href: "#", label: "Upgrade Guide" },
  //     { href: "#", label: "Browser Support" },
  //     { href: "#", label: "Dark Mode" },
  //   ],
  // },
  {
    id: "1",
    title: "Usefull Links",
    menus: [
      { href: "/", label: "Home" },
      { href: "/about", label: "About" },
      { href: "/contact", label: "Contact" },
      { href: "/sitemap", label: "Sitemap" },
    ],
  },
  {
    id: "2",
    title: " ",
    menus: [
      { href: "/faqs", label: "FAQs" },
      { href: "/return", label: "Return Policy" },
      { href: "/privacy", label: "Privacy Policy" },
      { href: "/terms", label: "Terms & Conditions" },
    ],
  },
  {
    id: "4",
    title: "Contact Info",
    menus: [
      { href: "tel:+971564119188", label: '(+971) 5641 19188' },
      { href: "mailto:info@bysufana.ae", label: "info@bysufana.ae" },
      { href: "javascript", label: "RAG Global Business Hub, G 01, Hilal Bank Building, Al-Qusais 2, Dubai, Po Box-237951" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title} &nbsp;
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <NavLink
                key={index}
                className={({ isActive }) => `text-neutral-6000 hover:text-black  ${ isActive ? "dark:text-custom-golden " : "dark:text-neutral-300 dark:hover:text-white" }`}
                to={item.href}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative pt-20 lg:pt-28 lg:pb-0 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 ">
        
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo className="footer-logo" />
          </div>
          <div className="hidden col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>

        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container mt-8">
        <div className="footer-bottom">
          <div className="copyright">© 2023 Sufana. All Rights Reserved.</div>
          <div className="mede-with">Made with
            <span className="icons-box-made">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
              </svg>
            </span>
            by <a href={"https://pmcommu.com/"}  rel="noreferrer" target="_blank">PM Communications</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Sufana</title>
        <meta name="description" content='At Sufana, we spice up your life – literally. Engage with our spice trading division, where we trade in aromatic spices and fast-moving consumer goods (FMCG), ensuring a flavorful journey for our clients. Sufana General Trading is where innovation meets excellence across a diverse spectrum of services. Our commitment extends to the artisanal realm with "Sufana," our exclusive brand offering handmade bath and body products. As a multifaceted company, we also specialize in delivering cutting-edge IT services, strategic investment solutions, and a seamless e-commerce experience. Additionally, we pioneer in virtual reality solutions, shaping the future of immersive experiences.' />
        <meta property="og:site_name" content="Sufana" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='At Sufana, we spice up your life – literally. Engage with our spice trading division, where we trade in aromatic spices and fast-moving consumer goods (FMCG), ensuring a flavorful journey for our clients. Sufana General Trading is where innovation meets excellence across a diverse spectrum of services. Our commitment extends to the artisanal realm with "Sufana," our exclusive brand offering handmade bath and body products. As a multifaceted company, we also specialize in delivering cutting-edge IT services, strategic investment solutions, and a seamless e-commerce experience. Additionally, we pioneer in virtual reality solutions, shaping the future of immersive experiences.' />
        <meta property="og:title" content="Sufana" />
        <meta property="og:image" itemProp="image" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:image:secure_url" content="https://backend.bysufana.ae/backend/img/logo/logo.png" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;

import React, { useRef, useState } from 'react';

// Slick SLider Files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Lightgellery FIlles
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

interface ProductImageSliderProps {
  images: string[];
}

const ProductImageSlider: React.FC<ProductImageSliderProps> = ({ images }) => {
  const mainSlider = useRef<Slider>(null);
  const thumbnailSlider = useRef<Slider>(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setMainSliderIndex(index);
      thumbnailSlider.current?.slickGoTo(index);
    },
  };

  const thumbnailSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
  };

  const handleThumbnailClick = (index: number) => {
    mainSlider.current?.slickGoTo(index);
  };

  return (
    <div className="product-slider-container">
      <Slider ref={mainSlider} {...sliderSettings} className="main-slider">
        {images.map((image, index) => (
          <div className="p-1" key={index}>
            <div
              className={`relative slider-item cursor-pointer thumbnail-item w-full h-[340px] rounded-xl overflow-hidden bg-[white] ${
                index === mainSliderIndex ? 'active' : ''
              }`}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-contain object-center"
                src={image}
                alt={`Product ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </Slider>

      <Slider ref={thumbnailSlider} {...thumbnailSettings} className="thumbnail-slider">
        {images.map((image, index) => (
          <div className="p-1" key={index}>
            <div
              className="thumbnail-item cursor-pointer w-full h-[100px] rounded-lg overflow-hidden bg-[white]"
              onClick={() => handleThumbnailClick(index)}
            >
              <img className="w-full h-full object-contain object-center" src={image} alt={`Thumbnail ${index + 1}`} />
            </div>
          </div>
        ))}
      </Slider>

      <LightGallery plugins={[lgZoom]}>
        {images.map((image, index) => ( 
          <a className="first:flex  absolute hidden justify-center items-center rounded-full left-[20px] bottom-[130px] bg-[#0f172a] w-[36px] h-[36px] color-[white]" key={index} href={image}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
            </svg>
          </a>
        ))}
      </LightGallery>
    </div>
  );
};

export default ProductImageSlider;

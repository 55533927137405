import React, { FC, useState, useEffect } from "react";
import Card12 from "./Card12";
import Card13 from "./Card13";
import axios from 'axios';
import API_HOST from '../../config/config';

export interface SectionMagazine5Props {}
export interface Blog {
  id: number;
  title: string;
  summary: string;
  description: string;
  image: string;
  link: string;
  sort_summary:string,
  posted_dt:string,
  added_by_name:string
}
const SectionMagazine5: FC<SectionMagazine5Props> = () => {

  const [mainBlog, setMainBlog] = useState<Blog|null>(null);
  const [otherBlogs, setOtherBlogs] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-home-data',{
          nopaginate : '1',
          status : '1',
          order : 'desc',
          type:'blog'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setMainBlog(response.data.data.main_blog);
            setOtherBlogs(response.data.data.other_blogs);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  

  return (
    <>
    <div className="nc-SectionMagazine5">
      { mainBlog && 
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        <Card12 blog={mainBlog}/> 
        { otherBlogs &&
        <div className="grid gap-6 md:gap-8">
          {otherBlogs.map((item, index) => (
            <Card13 key={index} blog={item}/>
          ))}
        </div>
        }
      </div>
      }
    </div>

    {/* --- skeletant-design --- */}
    <div className="skeletant-target hidden">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">

        <div>
          <div className="skeletant-design h-[320px]"></div>
          <div className="skeletant-design ld-no-img h-[30px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
          <div className="flex items-center mt-4">
            <div className="skeletant-design ld-no-img w-[24px] h-[24px] "></div>
            <div className="skeletant-design ld-no-img w-[100px] h-[10px] ml-2"></div>
          </div>
        </div>

        <div className="grid gap-6 md:gap-8">

          <div className="flex">
            <div className="w-full pr-4">
              <div className="skeletant-design ld-no-img h-[30px] mt-4"></div>
              <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              <div className="flex items-center mt-4">
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] "></div>
                <div className="skeletant-design ld-no-img w-[100px] h-[10px] ml-2"></div>
              </div>
            </div>
            <div className="skeletant-design w-[150px] h-[120px]"></div>
          </div>

          <div className="flex">
            <div className="w-full pr-4">
              <div className="skeletant-design ld-no-img h-[30px] mt-4"></div>
              <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              <div className="flex items-center mt-4">
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] "></div>
                <div className="skeletant-design ld-no-img w-[100px] h-[10px] ml-2"></div>
              </div>
            </div>
            <div className="skeletant-design w-[150px] h-[120px]"></div>
          </div>

          <div className="flex">
            <div className="w-full pr-4">
              <div className="skeletant-design ld-no-img h-[30px] mt-4"></div>
              <div className="skeletant-design ld-no-img h-[12px] mt-4"></div>
              <div className="flex items-center mt-4">
                <div className="skeletant-design ld-no-img w-[24px] h-[24px] "></div>
                <div className="skeletant-design ld-no-img w-[100px] h-[10px] ml-2"></div>
              </div>
            </div>
            <div className="skeletant-design w-[150px] h-[120px]"></div>
          </div>

        </div>

      </div>
    </div>
    {/* --- skeletant-design --- */}
    </>
  );
};

export default SectionMagazine5;

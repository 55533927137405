import React from 'react';

// Slick SLider Files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';


import ot1 from '../../images/ot/ot1.jpg'
import ot2 from '../../images/ot/ot2.jpg'
import ot3 from '../../images/ot/ot3.jpg'
import ot4 from '../../images/ot/ot4.jpg'
import ot5 from '../../images/ot/ot5.jpg'
import ot6 from '../../images/ot/ot6.jpg'
import ot7 from '../../images/ot/ot7.jpg'

const Mentorsslickslider = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  swipeToSlide: true,
  speed: 500,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "10%",
      },
    },
  ],
};

const ourteam = [
  {
      otiImg: ot1,
      otiName: "Suffaiya Kaliwala",
      otiTag: "CEO",
  },
  {
      otiImg: ot2,
      otiName: "Zainab Afzal",
      otiTag: "COO SUFANA",
  },
  {
      otiImg: ot3,
      otiName: "Zuha Iman",
      otiTag: "COO Body Bucket",
  },
  {
      otiImg: ot4,
      otiName: "Aboobucker Ameen",
      otiTag: "Sales Officer",
  },
  {
      otiImg: ot5,
      otiName: "Ahmad Faraz",
      otiTag: "Admin",
  },
  {
      otiImg: ot6,
      otiName: "Sana",
      otiTag: "Social Media Manager",
  },
  {
      otiImg: ot7,
      otiName: "Babar",
      otiTag: "Sales Associate",
  },
]

const PageOurTeam: React.FC = () => {

  return (
    <Slider className="mt-4 style-two" {...Mentorsslickslider}>

      {ourteam.map((item, index) => ( 
      <div className="slider-item-outer" key={index}>
        <div className="ourteam-item">
            <div className="oti-icon">
                <img src={item.otiImg} alt="" />
            </div>
            <div className="oti-name">{item.otiName}</div>
            <div className="oti-tag">{item.otiTag}</div>
        </div>
      </div>
      ))}
    </Slider>
  );
};

export default PageOurTeam;
